import { useApi } from "./useApi";
import InvestmentClient from "../clients/InvestmentClient";
import { useMutation } from "@tanstack/react-query";
import { useInvestmentSignatures } from "./useAgreements";
import { useEffect, useMemo, useState } from "react";
import { MAX_ADDITIONAL_SIGNERS } from "@equidefi/shared/constants/investments";
import { useToast } from "@chakra-ui/react";
import swal from "sweetalert";

export const useEditSigner = (investmentId) => {
  const investmentClient = useApi(InvestmentClient);

  return useMutation(async (data: Object) =>
    investmentClient.editSigner(investmentId, data)
  );
};

export const useDeleteSigner = () => {
  const investmentClient = useApi(InvestmentClient);

  return useMutation(async (signerId) =>
    investmentClient.deleteSigner(signerId)
  );
};

export const useCreateSigner = (investmentId) => {
  const investmentClient = useApi(InvestmentClient);

  return useMutation(async (data: Object) => {
    return investmentClient.addSigner(investmentId, data);
  });
};

export const useSetupSigners = (investmentId) => {
  const investmentClient = useApi(InvestmentClient);

  return useMutation(async () => {
    return investmentClient.setupSigners(investmentId);
  });
};

export const useInvestmentSigners = (
  investmentId: string,
  entityType: string,
  options: { onSuccess: () => {} }
) => {
  const [initialValues, setInitialValues] = useState({});
  const toast = useToast();

  const {
    data,
    refetch: refetchSignatures,
    isFetched,
  } = useInvestmentSignatures(investmentId);

  const setupSigners = useSetupSigners(investmentId);

  useEffect(() => {
    if (isFetched && !data?.signatures.length) {
      setupSigners.mutateAsync();
      refetchSignatures();
    }
  }, [data?.signatures]);

  const signers =
    data?.signatures?.filter((sp) => ![1, null].includes(sp.order)) || [];

  const canAddMoreSigners = useMemo(
    () => signers.length < MAX_ADDITIONAL_SIGNERS[entityType],
    [signers.length, entityType]
  );

  const addSigner = useCreateSigner(investmentId);
  const editSigner = useEditSigner(investmentId);
  const deleteSigner = useDeleteSigner();

  const onSubmit = async (data) => {
    try {
      // @ts-ignore
      if (initialValues?.email) {
        delete data.name;
        await editSigner.mutateAsync(data);
      } else {
        await addSigner.mutateAsync(data);
      }
      toast({
        status: "success",
        description: "Successfully saved signer",
      });

      await refetchSignatures();
      options.onSuccess();
      setInitialValues({});
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description:
          e.response?.data?.errors?.join(". ") ?? "Something went wrong",
      });
      return;
    }
  };

  const onDelete = async (signerId) => {
    const status = await swal({
      title: "Delete Signer",
      text: "Are you sure you want to delete the signer?",
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    });

    if (status) {
      try {
        await deleteSigner.mutateAsync(signerId);
        toast({
          status: "success",
          description: "The signer was deleted successfully.",
        });
      } catch (error) {
        console.error(error);
        toast({
          status: "error",
          description: "Something went wrong deleting the signer",
        });
      }
      refetchSignatures();
    }
  };

  const onDeleteMultipleSigners = async (signerIds: string[]): Promise<void> => {
    try {
      await Promise.all(signerIds.map(signerId => deleteSigner.mutateAsync(signerId)));
      
      toast({
        status: "warning",
        description: "You can only have two signers when investing as an Individual/Joint, additional signers were removed.",
      });
    } catch (error) {
      toast({
        status: "error",
        description: "An error occurred while deleting signers",
      });
    } finally {
      refetchSignatures();
    }
  };

  const onEdit = async (data) => {
    const filteredData = {
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      signing_party_id: data.signing_party_id,
      order: data.order,
    };
    setInitialValues(filteredData);
  };

  const onCreate = async () => {
    setInitialValues({});
  };

  return {
    signers,
    canAddMoreSigners,
    initialValues,
    onSubmit,
    onDelete,
    onDeleteMultipleSigners,
    onEdit,
    onCreate,
  };
};
