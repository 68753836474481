import { ApiClient } from "./ApiClient";

type StartParams = {
  offering_id: string;
  investment_id: string;
  citizenship: "US_CITIZEN" | "INTERNATIONAL";
  redirect_uri: string;
};

class InquiryClient extends ApiClient {
  async find(inquiryId: string) {
    const response = await this.get(`/v2/inquiries/${inquiryId}`);
    return response.data;
  }

  async start(data: StartParams) {
    const response = await this.post("/v2/inquiries/start", data);
    return response.data;
  }

  async status(userId: string, inquiryId: string) {
    const response = await this.get(
      `/v2/inquiries/status/${userId}/${inquiryId}`
    );

    return response.data;
  }

  async resume(inquiryId: string) {
    const response = await this.post(`/v2/inquiries/resume/${inquiryId}`);

    return response.data;
  }

  async getInvestmentInquiries(investmentId: string) {
    const response = await this.get(`/v2/inquiries/list/${investmentId}`);

    return response.data;
  }

  async emailInvestor(inquiryId: string, investmentId: string) {
    const response = await this.post(
      `/v2/inquiries/email/${investmentId}/${inquiryId}`
    );

    return response.data;
  }
}

export default InquiryClient;
