/* eslint-disable react-hooks/exhaustive-deps */
import { Button, HStack, Tooltip } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import swal from "sweetalert";

import OfferingClient from "@equidefi/portals/clients/OfferingClient";
import { StatusBadge } from "@equidefi/portals/components/investments/StatusBadge";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { userFullName } from "@equidefi/shared";
import { Heading } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

import TrauncheInvestmentModal from "../../components/offerings/TrauncheInvestmentModal";
import Spinner from "../../components/spinner";
import { CurrencyFormatter, NumberFormatter } from "../../constants/format";
import { isAdmin, isOfferingOwner } from "../../helpers";
import
  {
    useOffering,
    useOfferingTrauncheAvailableInvestments,
    useOfferingTraunches,
  } from "../../hooks/useOfferings";
import Header from "../../layouts/header";

const OfferingTraunches = () => {
  const { id } = useParams();
  const { data: user } = useCurrentUser();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { data: offering } = useOffering(id);
  const { data: traunches, isLoading, isSuccess } = useOfferingTraunches(id);

  const { data: available } = useOfferingTrauncheAvailableInvestments(id);

  const [currentInvestor, setCurrentInvestor] = useState(null);
  const offeringApi = useApi(OfferingClient);

  const goToTraunche = (trauncheId) => {
    history.push(`/offering/${id}/tranches/${trauncheId}`);
  };

  const handleCreate = async (user) => {
    const status = await swal({
      title: `Close Tranche`,
      text: `Sure you are ready to close this tranche of investors?`,
      buttons: ["Cancel", "Yes"],
      icon: "warning",
      dangerMode: true,
    });

    if (!status) return;

    try {
      await offeringApi.createTruanche(id);

      queryClient.invalidateQueries(["offerings", id]);

      await swal({
        title: `Done!`,
        text: `You've closed a tranche with ${available.length} investors`,
        icon: "success",
        dangerMode: true,
      });
    } catch (e) {
      swal({
        title: `Sorry!`,
        text: e.response.data?.errors?.join(", "),
        icon: "error",
        dangerMode: true,
      });

      throw e;
    }
  };

  const canEdit = useMemo(
    () => isAdmin(user) || isOfferingOwner(user, id),
    [user, id]
  );

  const anyAvailableInvestors = available?.length > 0;

  if (!offering || isLoading) {
    return <Spinner show />;
  }

  return (
    <>
      <Header name="Closing Tranches" title={offering.name} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6">
            <HStack w="full" align="center" justify="space-between" mb={4}>
              <Heading textStyle="h1" mb={0}>
                Available Investments to Close
              </Heading>
              {canEdit && (
                <Tooltip
                  isDisabled={anyAvailableInvestors}
                  hasArrow
                  placement="auto-start"
                  label="There must be at least one countersigned or completed investor to create a tranche"
                >
                  <Button
                    leftIcon={
                      !anyAvailableInvestors && <Icon.Info size="1em" />
                    }
                    size="sm"
                    isDisabled={!anyAvailableInvestors}
                    onClick={handleCreate}
                  >
                    Close Tranche
                  </Button>
                </Tooltip>
              )}
            </HStack>
            { available?.length > 0 && (
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-hover table-nowrap card-table">
                    <thead>
                      <tr>
                        <th>Total Investors</th>
                        <th>Total Amount</th>
                        <th>Total Unit Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{available?.length || 0}</td>
                        <td>{CurrencyFormatter.format(available?.reduce((sum, inv) => sum += Number(inv.amount), 0) ?? 0)}</td>
                        <td>{NumberFormatter.format(available?.reduce((sum, inv) => sum += Number(inv.quantity), 0) ?? 0)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div className="card">
              <div className="table-responsive">
                <table className="table table-hover table-nowrap card-table">
                  <thead>
                    <tr>
                      <th>Investor Name</th>
                      <th>Amount</th>
                      <th>Unit Quantity</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {available?.map((investment) => (
                      <tr key={investment.id}>
                        <td>{userFullName(investment.user)}</td>
                        <td>{CurrencyFormatter.format(investment.amount)}</td>
                        <td>{NumberFormatter.format(investment.quantity)}</td>
                        <td>
                          <StatusBadge action={investment.status} />
                        </td>
                        <th>
                          <Button
                            onClick={() => setCurrentInvestor(investment)}
                            colorScheme="gray"
                            size="xs"
                          >
                            Info
                          </Button>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <HStack mb={4}>
              <Heading textStyle="h1" mb={0}>
                Closed Tranches
              </Heading>
            </HStack>
            <div className="card">
              {isSuccess && traunches?.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-hover table-nowrap card-table">
                    <thead>
                      <tr>
                        <th>Closing Date</th>
                        <th>Amount</th>
                        <th>Quantity</th>
                        <th>Investors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {traunches?.map((traunche) => {
                        return (
                          <tr
                            onClick={() => goToTraunche(traunche.id)}
                            style={{ cursor: "pointer" }}
                            key={traunche.id}
                          >
                            <td>
                              {moment(traunche.finalized_date).format(
                                "MMM DD, YYYY"
                              )}
                            </td>
                            <td>
                              {CurrencyFormatter.format(
                                traunche.aggregations.total_investment_amount
                              )}
                            </td>
                            <td>
                              {NumberFormatter.format(
                                traunche.aggregations.total_investment_quantity
                              )}
                            </td>
                            <td>
                              {NumberFormatter.format(
                                traunche.aggregations.total_investments
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="card-body text-center text-muted p-5">
                  <p className="mb-0">No tranches created</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <TrauncheInvestmentModal
        investment={currentInvestor}
        onHide={() => setCurrentInvestor(null)}
      />
    </>
  );
};

export default OfferingTraunches;
