import
  {
    Badge,
    Tag as CTag,
    TagLabel,
    TagLeftIcon,
    Tooltip,
  } from "@chakra-ui/react";
import React from "react";

import { Icon } from "@equidefi/ui/icon";

const BadgeTypeMap = {
  PENDING: "gray",
  SUBMITTED: "blue",
  APPROVED: "green",
  REJECTED: "red",
  COMPLETED: "green",
  REVIEW: "blue",
  CLOSED: "green",
};

const BadgeIconMap = {
  PENDING: Icon.Clock,
  SUBMITTED: Icon.FileText,
  APPROVED: Icon.ThumbsUp,
  REJECTED: Icon.X,
  COMPLETED: Icon.Check,
  REVIEW: Icon.Search,
  CLOSED: Icon.Check,
};

const TooltipLabelMap = {
  REGISTRATION:
    "Registration: (Step 1): Investor has submitted name and email address",
  PROFILE:
    "Profile (Step 2): Investor enters name, entity (if applicable), and phone number. Profile created",
  SUBSCRIPTION:
    "Subscription (Step 3): Investor indication of interest and can view offering documents",
  PAYMENT:
    "Payment (Step 4): Investor selects a payment method CC/ACH/WT/Check",
  ADDRESS:
    "Address (Step 5): Investor selects citizenship and provides address",
  SIGNERS: "Signers (Step 6): Investor selects additional signers, if any",
  KYC: "KYC (Step 7): Investor KYC flow event",
  AGREEMENT: "Agreement (Step 8): Investor agreement e-signature flow",
  ACCREDITATION:
    "Accreditation (Step 9): Investor accreditation document upload step",
};

export const StatusBadge = ({ as: Tag = Badge, action, ...rest }) => {
  return (
    <Tag colorScheme={BadgeTypeMap[action] || "gray"} {...rest}>
      {action}
    </Tag>
  );
};

export const EventBadge = ({ event, action, isPlain = false, ...rest }) => {
  const BadgeIcon = BadgeIconMap[action];
  const colorScheme = isPlain ? "gray" : BadgeTypeMap[action] ?? "gray";

  return (
    <Tooltip placement="left" hasArrow label={TooltipLabelMap[event]}>
      <CTag colorScheme={colorScheme} {...rest}>
        {!isPlain && (
          <TagLeftIcon>
            <BadgeIcon />
          </TagLeftIcon>
        )}
        <TagLabel>{event}</TagLabel>
      </CTag>
    </Tooltip>
  );
};
