import { Table, TD, TH, TR } from "@ag-media/react-pdf-table";
import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

import
  {
    Paragraph,
    PDFDocument,
    PDFPage,
    PDFSection,
  } from "@equidefi/portals/components/PDF";
import { styles } from "@equidefi/portals/components/PDF.styles";
import { userFullName } from "@equidefi/shared";
import { StockTypeMap } from "@equidefi/shared/constants/offerings";

import { CurrencyFormatter, NumberFormatter } from "../../../constants/format";

const DATE_FORMAT = "MMM DD, YYYY";

export const TailPdf = ({
  issuer,
  offering,
  investments = [],
  quantity = 0,
  unitPrice = 0,
}) => {
  const settings =
    offering?.settings?.reduce((memo, setting) => {
      memo[setting.id] = setting.value;
      return memo;
    }, {}) ?? {};

  const date = moment();
  const stockType = StockTypeMap[offering.stock_type] ?? offering.stock_type;

  return (
    <PDFDocument>
      <PDFPage>
        <View style={{ width: "100%", textAlign: "center", marginBottom: 5 }}>
          <Text style={[{ marginBottom: 8 }, styles.bold]}>{issuer.name}</Text>
          <View style={{ fontSize: 12 }}>
            <Text>{issuer.address.line_1}</Text>
            <Text>
              {issuer.address.city}, {issuer.address.state}{" "}
              {issuer.address.postal_code}
            </Text>
            <Text>{issuer.phone}</Text>
          </View>
        </View>

        <PDFSection>
          <Paragraph>
            Date:{" "}
            <Text style={[styles.bold, styles.underline]}>
              {date.format(DATE_FORMAT)}
            </Text>
          </Paragraph>

          <View style={{ marginBottom: 15 }}>
            <Text>Attn: {settings?.transfer_agent_contact}</Text>
            <Text>{settings?.transfer_agent_company ?? ""}</Text>
            <Text>{settings?.transfer_agent_address1 ?? ""}</Text>
            <Text>{settings?.transfer_agent_address2 ?? ""}</Text>
            <Text>{settings?.transfer_agent_email ?? ""}</Text>
          </View>

          <Paragraph>
            Re:{" "}
            <Text style={styles.bold} wrap={false}>
              {issuer.name}
            </Text>{" "}
            (the "Company")
          </Paragraph>

          <Paragraph>
            Pursuant to the terms of the Company&apos;s Board Resolution dated{" "}
            {/* Empty Date Area */}
            <Text style={styles.underline}>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp;
            </Text>
            , please issue an aggregate of{" "}
            <Text style={[styles.bold, styles.underline]}>
              {NumberFormatter.format(quantity)}
            </Text>{" "}
            shares of the Company&apos;s {stockType} to the holders and in the
            amounts set forth in Schedule A attached hereto. The company has
            received full consideration, and the shares are fully paid and
            non-assessable. Cost Basis{" "}
            <Text style={[styles.bold, styles.underline]}>
              {CurrencyFormatter.format(unitPrice)}
            </Text>{" "}
            per share.
          </Paragraph>

          <Paragraph>
            The legend set forth below should be placed on the certificate
            referred to above:
          </Paragraph>

          <Paragraph>
            “THE SECURITIES REPRESENTED BY THIS CERTIFICATE HAVE NOT BEEN
            REGISTERED UNDER THE SECURITIES ACT OF 1933, AS AMENDED (THE “ACT”),
            AND MAY NOT BE SOLD, OFFERED FOR SALE, PLEDGED OR HYPOTHECATED OR
            OTHERWISE TRANSFERRED IN THE ABSENCE OF A REGISTRATION STATEMENT IN
            EFFECT WITH RESPECT TO SUCH SECURITIES UNDER THE ACT OR AN OPINION
            OF COUNSEL SATISFACTORY TO THE ISSUER OF THIS CERTIFICATE THAT AN
            EXEMPTION FROM REGISTRATION REQUIREMENTS OF THE ACT IS AVAILABLE
            WITH RESPECT TO SUCH TRANSFER. ANY SUCH TRANSFER MAY ALSO BE SUBJECT
            TO COMPLIANCE WITH APPLICABLE STATE SECURITIES LAWS.”
          </Paragraph>
          <Paragraph>
            Please issue the {stockType} in book entry form.
          </Paragraph>
          <Paragraph>
            If you have any questions or require any other documentation in
            connection herewith, please contact the undersigned.
          </Paragraph>

          <Paragraph>Very truly yours,</Paragraph>

          <Text style={styles.bold}>{userFullName(offering.signatory)}</Text>
          <Text style={styles.bold}>{offering.signatory.title}</Text>
        </PDFSection>
      </PDFPage>

      <Page size="A4" style={{ padding: "25px" }} wrap>
        <Text style={[styles.title, { marginBottom: 10 }]}>Schedule A</Text>

        <Table
          style={styles.table}
          tdStyle={{ padding: "2px" }}
          weightings={[0.4, 0.35, 0.1, 0.15]}
        >
          <TH fixed textAlign="left">
            <TD style={[styles.tableCell, styles.tableHeader, { fontSize: 9 }]}>
              Name
            </TD>
            <TD style={[styles.tableCell, styles.tableHeader, { fontSize: 9 }]}>
              Address
            </TD>
            <TD style={[styles.tableCell, styles.tableHeader, { fontSize: 9 }]}>
              Share Amount
            </TD>
            <TD style={[styles.tableCell, styles.tableHeader, { fontSize: 9 }]}>
              SSN/EIN
            </TD>
          </TH>

          {investments?.map((investment) => (
            <TR key={investment.id}>
              <TD style={[styles.tableCell, { fontSize: 10 }]}>
                <View>
                  <Text>{userFullName(investment.user)}</Text>
                  <Text style={{ color: "gray" }}>{investment.user.email}</Text>
                </View>
              </TD>
              <TD style={[styles.tableCell, { fontSize: 10 }]}>
                <View>
                  <Text>{investment.user.address_street_1}</Text>
                  <Text>
                    {investment.user.address_city},{" "}
                    {investment.user.address_state}{" "}
                    {investment.user.address_postal_code}
                  </Text>
                </View>
              </TD>
              <TD style={[styles.tableCell, { fontSize: 10 }, styles.right]}>
                {NumberFormatter.format(investment.quantity)}
              </TD>
              <TD style={[styles.tableCell, { fontSize: 10 }]}>
                {investment.persona_inquiry?.business_tax_id ??
                  investment.persona_inquiry?.ssn ??
                  "N/A"}
              </TD>
            </TR>
          ))}
        </Table>
      </Page>
    </PDFDocument>
  );
};
