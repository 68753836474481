import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import { useApi } from "./useApi";
import InquiryClient from "../clients/InquiryClient";

export const useInvestmentInquiries = (investmentId, options = {}) => {
  const inquiryApi = useApi(InquiryClient);

  return useQuery(
    ["offering", investmentId, "offering_agreements"],
    () => inquiryApi.getInvestmentInquiries(investmentId),
    {
      enabled: !!investmentId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      ...options,
    }
  );
};
