export const RESTRICTED_CONTENT_TYPES = {
  'png': 'image/png',
  'jpg': 'image/jpeg',
  'jpeg': 'image/jpeg',
  'tiff': 'image/tiff',
  'webp': 'image/webp',
  'heic': 'image/heic',
  'heiv': 'image/heiv',
  'pdf': 'application/pdf'
}

export const ALL_CONTENT_TYPES = {
  'png': 'image/png',
  'jpg': 'image/jpeg',
  'jpeg': 'image/jpeg',
  'tiff': 'image/tiff',
  'webp': 'image/webp',
  'heic': 'image/heic',
  'heiv': 'image/heiv',
  'pdf': 'application/pdf',
  'doc': 'application/msword',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'odp': 'application/vnd.oasis.opendocument.presentation',
  'ods': 'application/vnd.oasis.opendocument.spreadsheet',
  'ppt': 'application/vnd.ms-powerpoint',
  'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'xls': 'application/vnd.ms-excel',
  'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}
