import { useMutation } from "@tanstack/react-query";
import InvestmentClient from "../clients/InvestmentClient";
import { useApi } from "./useApi";

export const useCreateInvestment = (id: string, options = {}) => {
  const investmentApi = useApi(InvestmentClient);
  return useMutation((data: { offering_id: string, bcode?: string }) => investmentApi.create(data.offering_id, data.bcode), options);
}

export const useStartReinvestment = (id: string, options = {}) => {
  const investmentApi = useApi(InvestmentClient);
  return useMutation((data: { offering_id: string, bcode?: string }) => investmentApi.create(data.offering_id, data.bcode, true), options);
}

export const useUpdateInvestment = (id: string, options = {}) => {
  const investmentApi = useApi(InvestmentClient);
  return useMutation((data: object) => investmentApi.update(id, data), options);
};
