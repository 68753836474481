/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AWSClient from "@equidefi/portals/clients/AWSClient";
import { usePresignedPublicUrl } from "@equidefi/portals/hooks/useUpload";
import { ALL_CONTENT_TYPES } from "@equidefi/shared/constants/files";

import { Input, Textarea } from "@equidefi/ui";
import { Button, ButtonGroup, Stack } from "@chakra-ui/react";

import FormControl from "../../components/formControl";
import Spinner from "../../components/spinner";
import Header from "../../layouts/header";

import
  {
    createIssuer,
    getIssuerById,
    updateIssuer,
  } from "../../helpers";

import { AddressAutocomplete } from "../../components/issuer/AddressAutocomplete";
import { issuerCreateSchema } from "../../constants/forms";
import states from "../../data/states.json";

const acceptedImageTypes = ["image/jpeg", "image/png"];

const data = {
  name: "",
  email: "",
  phone: "",
  linkedin_url: "",
  website_url: "",
  profile: "",
  cik: "",
  ein: "",
  sic: "",
  state_of_incorporation: "",
  doing_business_as: "",
  business_address: {
    line_1: "",
    city: "",
    state: "",
    country: "United States",
    postal_code: "",
  },
  address: {
    line_1: "",
    city: "",
    state: "",
    country: "United States",
    postal_code: "",
  },
};

const IssuerEditor = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [issuer, setIssuer] = useState(data);

  const [fineSpinner, setFineSpinner] = useState(false);
  const [spinner, showSpinner] = useState(false);
  const presignedPublicUrl = usePresignedPublicUrl();

  const createIssuerMutation = useMutation((_value) => createIssuer(_value));
  const updateIssuerMutation = useMutation((_value) =>
    updateIssuer(id, _value)
  );

  useEffect(() => {
    if (id) {
      showSpinner(true);
      getIssuerById(id).then((issuer) => {
        setIssuer(issuer);
        showSpinner(false);
      });
    }
  }, []);

  const handleOnSubmit = async (data) => {
    showSpinner(true);
    data.address.state = data?.address?.state?.label ?? data.address.state;
    if (id) {
      try {
        const issuer = await updateIssuerMutation.mutateAsync(data);
        toast.success(`Issuer '${data?.name}' has been updated`);
        history.push(`/issuer/${issuer?.id}/offerings`);
        showSpinner(false);
      } catch (err) {
        showSpinner(false);
        toast.error(err.response.data.message);
      }
    } else {
      try {
        const issuer = await createIssuerMutation.mutateAsync(data);
        toast.success(`Issuer '${data.name}' has been created`);
        history.push(`/issuer/${issuer?.id}/offerings`);
        showSpinner(false);
      } catch (err) {
        showSpinner(false);
        toast.error(err.response.data.message);
      }
    }
  };

  const handleImageUpload = async (e, setFieldValue) => {
    const file = e.target.files[0];
    const filename = file.name.split(' ').join('_');
    const extension = filename.split(".").pop().toLowerCase();

    if (!acceptedImageTypes.includes(file.type))
      return toast.error("File format not supported");
    setFineSpinner(true);

    const response = await presignedPublicUrl.mutateAsync({
      filename: filename,
      extension: extension,
    });
    const client = new AWSClient();
    client
      .s3Upload(response.signedUrl, file, ALL_CONTENT_TYPES[extension], file.size)
      .then(() => {
        setFieldValue("photo_url", response.url);
      })
      .catch((e) => {
        console.log(e);
        toast({
          status: "error",
          description: "Your file failed to upload!",
        });
      })
      .finally(() => {
        setFineSpinner(false);
      });
  };

  return (
    <>
      <Header title="EquiDeFi" name={id ? "Edit Issuer" : "Create Issuer"} />
      <div className="container-fluid mb-5">
        <Formik
          enableReinitialize={true}
          initialValues={issuer}
          validationSchema={issuerCreateSchema}
          onSubmit={handleOnSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
            getFieldProps,
            setValues,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <div className="row justify-content-between align-items-center form-group">
                  <div className="col">
                    <div className="d-flex flex-column align-items-start">
                      <div className="ms-n2">
                        <h4 className="mb-1">Your Company Logo</h4>
                      </div>

                      <div className="form-control">
                        <div className="row align-items-center">
                          <div className="d-flex gap-4 align-items-center flex-row justify-content-start">
                            <div className="py-2">
                              <div className="issuer-logo">
                                <Spinner show={fineSpinner}>
                                  <img
                                    className="issuer-logo-height"
                                    src={
                                      values.photo_url
                                        ? values.photo_url
                                        : "/img/company.png"
                                    }
                                    alt="logo"
                                  />
                                </Spinner>
                              </div>
                            </div>

                            <div className="form-input">
                              <input
                                type="file"
                                id="myfile"
                                name="myfile"
                                accept={acceptedImageTypes.join(",")}
                                onChange={(e) =>
                                  handleImageUpload(e, setFieldValue)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Input
                  label="Name"
                  isRequired
                  placeholder="e.g. ABC Inc"
                  {...getFieldProps("name")}
                  error={errors.name}
                  isInvalid={errors.name && touched.name}
                />

                <Input
                  type="email"
                  label="Email"
                  placeholder="e.g. support@abc.com"
                  error={errors.email}
                  isInvalid={errors.email && touched.email}
                  isRequired
                  {...getFieldProps("email")}
                />

                <FormControl
                  type="phone"
                  name="phone"
                  title="Phone"
                  placeholder="eg: +1 (444) 444-4444"
                  value={values.phone}
                  error={errors.phone}
                  required
                  onChange={handleChange}
                />

                <Input
                  type="url"
                  label="Website Url"
                  placeholder="eg: https://abcinc.com"
                  isRequired
                  error={errors.website_url}
                  isInvalid={errors.website_url && touched.website_url}
                  {...getFieldProps("website_url")}
                />

                <Input
                  type="url"
                  label="LinkedIn Url"
                  placeholder="e.g. https://linkedin.com/abc"
                  error={errors.linkedin_url}
                  isInvalid={errors.linkedin_url && touched.linkedin_url}
                  {...getFieldProps("linkedin_url")}
                />

                <Textarea
                  label="Profile"
                  error={errors.profile}
                  isInvalid={errors.profile && touched.profile}
                  {...getFieldProps("profile")}
                />

                {values.cik && (
                  <>
                    <input
                      value={values.sec_phone}
                      name="sec_phone"
                      type="hidden"
                      onChange={handleChange}
                    />
                    <input
                      value={values.state_of_incorporation}
                      name="state_of_incorporation"
                      type="hidden"
                      onChange={handleChange}
                    />
                  </>
                )}

                {!id && (
                  <>
                    <hr />

                    <Stack direction={{ base: "column", lg: "row" }} gap={{ base: 2, lg: 5 }}>
                      <Input
                        label="CIK"
                        placeholder="CIK number from SEC"
                        error={errors.cik}
                        isInvalid={errors.cik && touched.cik}
                        {...getFieldProps("cik")}
                      />

                      <Input
                        label="EIN"
                        placeholder="EIN number from SEC"
                        error={errors.ein}
                        isInvalid={errors.ein && touched.ein}
                        {...getFieldProps("ein")}
                      />

                      <Input
                        label="SIC"
                        placeholder="Standard Industrial Classification code from the SEC"
                        error={errors.sic}
                        isInvalid={errors.sic && touched.sic}
                        {...getFieldProps("sic")}
                      />
                    </Stack>
                  </>
                )}

                <div className="col">
                  <Input
                    label="Doing Business As"
                    error={errors.doing_business_as}
                    isInvalid={
                      errors.doing_business_as && touched.doing_business_as
                    }
                    {...getFieldProps("doing_business_as")}
                  />
                </div>

                <hr />

                <h2>Addresses</h2>

                <hr />

                <h3>Mailing Address</h3>

                <AddressAutocomplete
                  setValues={setValues}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  addressName="address"
                />

                <hr />

                <h3>Business Address</h3>

                <AddressAutocomplete
                  setValues={setValues}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  addressName="business_address"
                />

                <hr />

                <ButtonGroup size="sm">
                  <Button type="submit">{id ? "Update" : "Create"}</Button>
                  <Button
                    as={Link}
                    variant="ghost"
                    colorScheme="gray"
                    to={id ? `/issuer/${id}/offerings` : "/issuer"}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default IssuerEditor;
