import {
  Badge,
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { sortBy, uniqBy } from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import NumberFormat from "react-number-format";
import swal from "sweetalert";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { Icon } from "@equidefi/ui/icon";

import { Text } from "@equidefi/ui";
import { isAdmin, isIssuerOwner, isOfferingOwner } from "../../../helpers";
import {
  usePaymentConfirm,
  useTerminatePayment,
} from "../../../hooks/useStripe";
import ManualPaymentModal from "./ManualPaymentModal";
import ManualRefundModal from "./ManualRefundModal";

const PAYMENT_BADGE_MAPS = {
  created: { color: "blue", text: "Payment Pending" },
  requires_action: { color: "gray", text: "Requires Action" },
  requires_payment_method: {
    color: "gray",
    text: "Requires Payment Method",
  },
  requires_capture: {
    color: "blue",
    text: "Requires Capture",
  },
  processing: { color: "green", text: "Payment Processing" },
  succeeded: { color: "green", text: "Payment Successful" },
  payment_failed: { color: "red", text: "Payment Failed" },
  canceled: { bg: "orange", text: "Canceled" },
  manual_payment_succeeded: { color: "green", text: "Manual Payment" },
  manual_refund_succeeded: { color: "orange", text: "Manual Refund" },
};

function stripePaymentUrl(paymentIntentId, stripeAccountId) {
  const base = new URL("https://dashboard.stripe.com");

  const prefix = stripeAccountId ? `connect/accounts/${stripeAccountId}` : ``;

  return new URL(`${prefix}/payments/${paymentIntentId}`, base);
}

/**
 *
 * @param {object} props
 * @param {string} props.investmentId
 * @param {{ data: import("@stripe/stripe-js").PaymentIntentResult, [key: string]: unknown }} props.payment
 * @returns
 */
const PaymentRow = ({ investmentId, payment, offering }) => {
  const { data: user } = useCurrentUser();

  const canCapturePayment = useMemo(() => {
    return (
      (isAdmin(user) ||
        isOfferingOwner(user, offering?.id) ||
        isIssuerOwner(user, offering?.issuer_id)) &&
      payment?.status === "requires_capture"
    );
  }, [user, offering?.id, offering?.issuer_id, payment?.status]);

  const capturePayment = usePaymentConfirm();

  const handleCapturePayment = async () => {
    const status = await swal({
      title: "Confirm Payment?",
      text: "Are you sure ready to capture this payment?",
      buttons: ["Cancel", "Yes"],
      icon: "warning",
      dangerMode: true,
    });

    if (!status) return;

    await capturePayment.mutateAsync({ investmentId, paymentId: payment.id });
  };

  const terminatePayment = useTerminatePayment();

  const handleTerminatePayment = async () => {
    const status = await swal({
      title: "Terminate Payment?",
      text: "Are you sure you want to terminate this payment?",
      buttons: ["Cancel", "Yes"],
      icon: "warning",
      dangerMode: true,
    });

    if (!status) return;

    await terminatePayment.mutateAsync({ investmentId, paymentId: payment.id });
  };

  const badgeConfig = PAYMENT_BADGE_MAPS[payment.status];

  const created = payment.data?.created
    ? moment(payment.data?.created * 1000)
    : null;
  const capturableBefore = payment?.data?.latest_charge?.payment_method_details
    ?.card?.capture_before
    ? moment(
        payment?.data?.latest_charge?.payment_method_details?.card
          ?.capture_before * 1000
      )
    : moment(created).clone().add(5, "days");
  
  const capturePaymentExpired = capturableBefore < moment();

  return (
    <tr key={payment.id}>
      <td className="align-baseline">
        {payment.payment_intent_id ? "Stripe" : "Manual"}
      </td>
      <td className="align-baseline">
        {created && (
          <Tooltip label={created.format("MMM DD, YYYY h:mma ZZ")}>
            {created.format("ll")}
          </Tooltip>
        )}
      </td>
      <td className="text-center align-baseline">
        {badgeConfig && (
          <Badge fontSize="0.65em" colorScheme={badgeConfig.color ?? "gray"}>
            {badgeConfig.text}
          </Badge>
        )}
        {canCapturePayment && (
          <Text textStyle="subtext" mb={0}>
            {capturePaymentExpired ? "Expired" : `Capturable before ${capturableBefore.format("ll")}`}
          </Text>
        )}
      </td>
      <td className="text-end align-baseline">
        <NumberFormat
          value={payment.amount}
          displayType="text"
          decimalScale={2}
          fixedDecimalScale={2}
          thousandSeparator
          prefix="$"
        />
      </td>
      <td className="align-baseline">
        <ButtonGroup>
          {canCapturePayment && !capturePaymentExpired && (
            <>
              <Tooltip label="Capture Funds" hasArrow>
                <IconButton
                  icon={<Icon.Shield size="1em" />}
                  colorScheme="gray"
                  size="xs"
                  aria-label="Capture Funds"
                  onClick={handleCapturePayment}
                />
              </Tooltip>
              <Tooltip label="Terminate Payment" hasArrow>
                <IconButton
                  icon={<Icon.Trash size="1em" />}
                  colorScheme="red"
                  size="xs"
                  aria-label="Terminate Payment"
                  onClick={handleTerminatePayment}
                />
              </Tooltip>
            </>
          )}
          {payment.payment_intent_id ? (
            <IconButton
              as="a"
              icon={<Icon.ExternalLink size="1em" />}
              colorScheme="gray"
              size="xs"
              aria-label="Go to payment on Stripe"
              href={stripePaymentUrl(
                payment.payment_intent_id,
                offering?.stripe_account_id
              )}
              target="_blank"
              rel="noreferrer noopener"
            >
              Go
            </IconButton>
          ) : null}
        </ButtonGroup>
      </td>
    </tr>
  );
};

const InvestmentPayments = ({
  investment,
  offering,
  canEdit = false,
  totalPaid = 0,
  totalBalance = 0,
  onUpdatePayments = () => {},
}) => {
  const [modalPayment, showPaymentModal] = useState(false);
  const [modalRefund, showRefundModal] = useState(false);

  const isInvestmentClosed = useMemo(
    () => !!investment?.offering_traunche_id,
    [investment?.offering_traunche_id]
  );

  const canAddPayment = useMemo(
    () => totalBalance > 0 && !isInvestmentClosed,
    [totalBalance, isInvestmentClosed]
  );

  const canAddRefund = useMemo(
    () => totalPaid > 0 && !isInvestmentClosed,
    [totalPaid, isInvestmentClosed]
  );

  const payments = useMemo(
    () =>
      sortBy(
        uniqBy(
          [
            ...(investment?.stripe_payments ?? []),
            ...(investment?.payments ?? []),
          ],
          "id"
        ),
        (p) => moment(p.create_date)
      ),
    [investment]
  );

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-header-title">Payments</h4>

          {offering?.status === "Active" && canEdit && (
            <ButtonGroup size="xs" colorScheme="gray">
              {canAddPayment && (
                <Button onClick={() => showPaymentModal(true)}>
                  Add Manual Payment
                </Button>
              )}
              {canAddRefund && (
                <Button onClick={() => showRefundModal(true)}>
                  Add Manual Refund
                </Button>
              )}
            </ButtonGroup>
          )}
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Type</th>
              <th>Date</th>
              <th className="text-center">Status</th>
              <th className="text-end">Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <PaymentRow
                investmentId={investment?.id}
                payment={payment}
                offering={offering}
                key={payment.id}
              />
            ))}
          </tbody>
        </table>
      </div>

      {createPortal(
        <ManualRefundModal
          isVisible={investment?.id && modalRefund}
          investmentId={investment?.id}
          totalPaid={totalPaid}
          onHide={() => showRefundModal(false)}
          onSubmit={() => onUpdatePayments?.()}
        />,
        document.body
      )}
      {createPortal(
        <ManualPaymentModal
          isVisible={investment?.id && modalPayment}
          investmentId={investment?.id}
          totalBalance={totalBalance}
          onHide={() => showPaymentModal(false)}
          onSubmit={() => onUpdatePayments?.()}
        />,
        document.body
      )}
    </>
  );
};

export default InvestmentPayments;
