import { ApiClient } from "./ApiClient";
import axios from "axios";

class AWSClient extends ApiClient {

  async s3Upload(url: string, binaryFile: any, contentType: string) {
    return axios.put(url, binaryFile, {
      transformRequest: (data, headers) => {
        delete headers.common;
        return data;
      },
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": contentType,
        "Accept": "*/*"
      }
    });
  }
}

export default AWSClient;
