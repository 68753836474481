import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { isAdmin, isIssuerOwner } from "../../../helpers";
import { useOffering } from "../../../hooks/useOfferings";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useInvestmentInquiries } from "@equidefi/portals/hooks/useInquiries";
import { InquiryRow } from "./InquiryRow";
import { Flex } from "@chakra-ui/react";

const InvestmentInquiry = () => {
  const { data: me } = useCurrentUser();
  const { id: offeringId, investment_id: investmentId } = useParams();

  const { data: offering } = useOffering(offeringId);
  const { data: inquiries } = useInvestmentInquiries(investmentId);

  const canEdit = useMemo(
    () => isAdmin(me) || isIssuerOwner(me, offering?.issuer_id),
    [offering?.issuer_id, me]
  );

  if (!inquiries || !inquiries.length) {
    return null;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">KYC/KYB</h4>
      </div>
      <div className="card-body">
        <Flex direction="column" gap="4">
          {inquiries.map((inquiry) => (
            <InquiryRow inquiry={inquiry} canEdit={canEdit} />
          ))}
        </Flex>
      </div>
    </div>
  );
};

export default InvestmentInquiry;
